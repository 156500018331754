<template>
  <div>
    <b-tabs>
      <b-tab active v-if="acesso.RelatorioRenegociacaoDividas">
        <template #title>
          <feather-icon icon="TrendingDownIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Dívidas</span>
        </template>
        <RelatorioRenegociacaoDividas :userData="userData" />
      </b-tab>
      <b-tab v-if="acesso.RelatorioRenegociacaoAntecipacao">
        <template #title>
          <feather-icon icon="TrendingUpIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Antecipação</span>
        </template>
        <RelatorioRenegociacaoAntecipacao :userData="userData" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
  import RelatorioRenegociacaoDividas from './relatorioRenegociacaoDividas.vue'
  import RelatorioRenegociacaoAntecipacao from './relatorioRenegociacaoAntecipacao.vue'
  export default {
    components: {
      RelatorioRenegociacaoDividas,
      RelatorioRenegociacaoAntecipacao,
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        acesso: {
          RelatorioRenegociacaoDividas: false,
          RelatorioRenegociacaoAntecipacao: false,
        },
      }
    },
    mounted() {
      if (this.userData.role == 'master') {
        this.acesso.RelatorioRenegociacaoDividas = true
        this.acesso.RelatorioRenegociacaoAntecipacao = true
      } else {
        this.acesso.RelatorioRenegociacaoDividas = this.userData.GrupoAcesso.RelatorioRenegociacaoDividas
        this.acesso.RelatorioRenegociacaoAntecipacao = this.userData.GrupoAcesso.RelatorioRenegociacaoAntecipacao
      }
    },
  }
</script>
